<template>
	<div class="confirmOrder">
		<div class="confirmOrder_tit">请核对您的订单信息</div>
		<div class="course">
			<div class="course_tit">专题列表</div>
			<div class="courseList">
				<div class="courseItem">
					<img :src="courseDetail.zt_thumb" v-if="courseDetail.zt_thumb" class="cou_thumb" />
					<img src="@/views/images/coursesImg.png" class="cou_thumb" v-else />
					<div class="courseInfo">
						<p class="cou_name">{{courseDetail.zt_name}}</p>
						<p class="cou_year">行业：{{courseDetail.dic_name.toString()}}</p>
						<p class="cou_year">总课程：{{courseDetail.zt_count}}门，共{{courseDetail.zt_sumtime}}学时</p>
					</div>
					<div class="cou_price">价格：￥{{courseDetail.zt_price}}</div>
				</div>
			</div>
			<div class="sumPrice">
				<span class="sumText">共1个专题，专题总金额￥{{courseDetail.zt_price}}</span>
				<span>应付：</span>
				<span class="sumBs">￥</span>
				<span class="sumNum">{{courseDetail.zt_price}}</span>
			</div>
		</div>
		<div class="payInfo">
			<div class="pay_tit">支付方式</div>
			<div class="paytype">
				<p :class="{active:paytype=='alipay'}" @click="chosePaytype('alipay')"><img src="@/views/images/zfbpay.png" /></p>
				<p :class="{active:paytype=='wechat'}" @click="chosePaytype('wechat')"><img src="@/views/images/wechatpay.png" /></p>
			</div>
			<div class="pay_btn_notice">
				<p class="payBtn" v-loading.fullscreen.lock="loading" @click="placeOrder">提交订单</p>
				<p class="payNotice">
					<el-checkbox v-model="isAgree">我已阅读并同意</el-checkbox>
					<span class="noticeText" @click="agreementVisible=true">《购买须知》</span>
				</p>
			</div>
		</div>
		<!-- 购买须知 -->
		<el-dialog title="购买须知" :visible.sync="agreementVisible" width="800px" custom-class="agreementDialog" :close-on-click-modal="false">
			<div class="agreementCon" v-html="agreementCon">
				
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import { Loading } from 'element-ui';
	export default {
	    data() {
			return {
				loading:false,
				courseList:[],//课程列表
				total:0,//总课程数,
				totalPrice:0,//总价格
				isAgree:false,//是否同意
				paytype:"alipay",//支付方式
				agreementVisible:false,//购买须知弹窗
				agreementCon:"",//购买须知内容
				isMakeup:false,//是否补考
				courseDetail:{
					dic_name:[]
				},//专题详情
			}
		},
		methods:{
			//获取课程详情
			getCourseList(){
				this.$http.post(this.PublicJs.publicPath + "/api/website/Zt/getDetail", {
					zt_id:this.$route.query.zt_id,
					token:window.sessionStorage.getItem('token')
				}, {
					emulateJSON: true
				}).then(response => {
					if(response.data.code == 1){
						this.courseDetail = response.data.data;
					}else{
						this.$message({
							message: response.data.msg,
							type: 'warning',
							showClose: true
						});
					}
				}, response => {
					if(response.status == 401){
						var that = this;
						this.$message({
							message: "您的账号在别处登录，请重新登录",
							type: 'warning',
							showClose: true
						});
						window.sessionStorage.removeItem('user_id')
						window.sessionStorage.removeItem('token')
						window.sessionStorage.removeItem('userinfo')
						setTimeout(function(){
							that.$router.go(0)
						},2000); 
						
					}
				});
			},
			//获取购买须知
			getAgreement(){
				this.$http.post(this.PublicJs.publicPath + "/api/student.index/agreement.html", {
					type:"agr_notice_content"
				}, {
					emulateJSON: true
				}).then(response => {
					if(response.data.code == 1){
						this.agreementCon = response.data.data.agr_notice_content;
					}else{
						this.$message({
							message: response.data.msg,
							type: 'warning',
							showClose: true
						});
					}
				}, response => {
					console.log(response)
				});
			},
			//选择支付方式
			chosePaytype(val){
				this.paytype = val;
			},
			//提交订单
			placeOrder(){
				if(this.isAgree){
					var course_id = this.PublicJs.trimSpace(this.PublicJs.Decrypt(this.$route.query.zt_id).split(","))
					for(var i=0;i<course_id.length;i++){
						course_id[i] = this.PublicJs.Encrypt(course_id[i])
					}
					this.loading=true;
					this.$http.post(this.PublicJs.publicPath + "/api/student.Order/add.html", {
						origin:"",
						type:2,
						zt_id:course_id,
						token:window.sessionStorage.getItem('token'),
					}, {
						emulateJSON: true
					}).then(response => {
						this.loading=false;
						if(response.data.code == 1){
							this.$router.push({
								path: "/payment",
								query: {
									order_id:response.data.data.ids,
									pay_type:this.PublicJs.Encrypt(this.paytype),
									totalPrice:this.PublicJs.Encrypt(this.courseDetail.zt_price),
								}
							});
						}else if(response.data.code == 2){
							this.$message({
								message: response.data.msg,
								type: 'warning',
								showClose: true
							});
							this.$router.push({
								path: "/order"
							});
						}else if(response.data.code == 3 || response.data.code == 4){
							this.$message({
								message: response.data.msg,
								type: 'warning',
								showClose: true
							});
							this.$router.push({
								path: "/course"
							});
						}else{
							this.$message({
								message: response.data.msg,
								type: 'warning',
								showClose: true
							});
						}
					}, response => {
						if(response.status == 401){
							var that = this;
							this.$message({
								message: "您的账号在别处登录，请重新登录",
								type: 'warning',
								showClose: true
							});
							window.sessionStorage.removeItem('user_id')
							window.sessionStorage.removeItem('token')
							window.sessionStorage.removeItem('userinfo')
							setTimeout(function(){
								that.$router.go(0)
							},2000); 
							
						}
					});
				}else{
					this.$message({
						message: "请阅读并勾选购买须知",
						type: 'warning',
						showClose: true
					});
				}
			}
		},
		created(){
			if(this.$route.query.isMakeup){
				this.isMakeup = true;
			}
			//获取课程详情
			this.getCourseList();
			//获取购买须知
			this.getAgreement();
		}
	}
</script>

<style scoped="scoped">
	.confirmOrder{
		width: 1200px;
		margin: 0 auto;
		padding-bottom: 90px;
	}
	.confirmOrder_tit{
		font-size: 22px;
		color: #333;
		line-height: 78px;
		font-weight: bold;
	}
	.course{
		width: 100%;
		background-color: #fff;
	}
	.course_tit{
		width: 100%;
		height: 38px;
		line-height: 38px;
		background-color: #EEF1FE;
		font-size: 14px;
		color: #333;
		padding-left: 25px;
	}
	.courseList{
		padding: 0 25px;
	}
	.courseItem{
		width: 100%;
		height: 112px;
		border-bottom: 1px solid #EEEEEE;
		position: relative;
		padding-left: 136px;
	}
	.cou_thumb{
		width: 120px;
		height: 72px;
		border-radius: 4px;
		position: absolute;
		left: 0;
		top: 20px;
	}
	.courseInfo{
		padding-top: 7px;
	}
	.cou_name{
		font-size: 16px;
		color: #333;
		line-height: 40px;
		overflow: hidden;
		text-overflow:ellipsis;
		white-space: nowrap;
	}
	.cou_type{
		display: inline-block;
		height: 22px;
		padding: 0 5px;
		background-color: #ffac3d;
		color: #FFF;
		border-radius: 4px;
		font-size: 12px;
		line-height: 22px;
		margin-right: 8px;
	}
	.cou_year{
		font-size: 14px;
		color: #333;
		line-height: 24px;
	}
	.cou_price{
		line-height: 112px;
		position: absolute;
		top: 0;
		right: 0;
		font-size: 14px;
		color: #333;
	}
	.sumPrice{
		padding: 0 25px;
		line-height: 72px;
		text-align: right;
		font-size: 14px;
		color: #333;
	}
	.sumText{
		margin-right: 30px;
	}
	.sumBs{
		color: #f30c0c;
	}
	.sumNum{
		color: #f30c0c;
		font-size: 22px;
	}
	.payInfo{
		width: 100%;
		background-color: #fff;
		margin-top: 22px;
		padding: 0 27px 54px 27px;
		position: relative;
	}
	.pay_tit{
		line-height: 57px;
		margin-bottom: 15px;
		font-size: 16px;
		color: #333;
	}
	.paytype::after{
		content: "";
		clear: both;
		display: block;
	}
	.paytype p{
		width: 220px;
		height: 60px;
		border: 1px solid #cdcdcd;
		border-radius: 2px;
		float: left;
		margin-right: 54px;
		padding-left: 30px;
		cursor: pointer;
	}
	.paytype p.active{
		background-image: url(../images/chosebg.png);
		background-position: bottom right; 
		background-repeat: no-repeat;
		border: 1px solid #5d7af2;
	}
	.pay_btn_notice{
		position: absolute;
		right: 63px;
		top: 55px;
		text-align: right;
	}
	.payBtn{
		display: inline-block;
		width: 120px;
		height: 40px;
		text-align: center;
		line-height: 40px;
		border-radius: 20px;
		background-color: #5d7af2;
		color: #fff;
		font-size: 14px;
		cursor: pointer;
	}
	.noticeText{
		font-size: 14px;
		color: #f30c0c;
		cursor: pointer;
	}
	.payNotice{
		margin-top: 16px;
	}
</style>
